import React,{useState} from 'react'

import {withRouter,Redirect,Link} from 'react-router-dom'
import {useAuth} from '../../service/authContext'
import { BeatLoader } from 'react-spinners';
import Recruiter from '../../assets/images/recruiter.png'



function LoginRc(props){
 const[email,setEmail] = useState('')
 const[password,setPassword] = useState('')
 const {loginStateRc,loginRc,loading} = useAuth(); 

 const onChangeEmail = (e)=>{
   setEmail(e.target.value)
 }
 const onChangePassword = (e)=>{
   setPassword(e.target.value)
 }


 return !loginStateRc ? (
     <div className="main">
       <div className="login">
        <div className="containerLogin">
          <div className="row">
              <img src={Recruiter} className="jobIcon-R" style={{backgroundColor:'#0b5dbd',padding:5}}/>
              <h3 className="forgot-header">Recruiter Login</h3>
          </div> 
          <div className="row">
            <h3>Email</h3>  
          </div>   
          <div className="row">
           <input 
           className="input"
           name="email"
           placeholder="  Enter your email"
           color={'#d3d3d3'}
           value={email}
           onChange={onChangeEmail}/>
          </div>
          <div className="row">
           <h3>Password</h3>
          </div>
          <div className="row">
           <input 
           className="input"
           placeholder="  Enter your password"
           name="password"
           type="password"
           value={password}
           onChange={onChangePassword}
           />
          </div>
          <div className="row" style={{margin:0,padding:0}}> 
            <Link to="/accounts/forgot/recruiter">
             <p className="forgot-para">Forgot Password</p>
             </Link>
          </div>
          <div className="row">
          <button onClick={(e)=>{
            loginRc(email,password)}
          }>LOGIN</button>
          </div>
        </div>
        <span className="loader">
        <BeatLoader
          size={13}
          color={"#7B8B8C"}
          loading = {loading}
        />
        </span>
       </div>  
     </div>
 ) : ( <Redirect to='/recruiter'/> )
}

export default withRouter(LoginRc)