
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect,withRouter } from "react-router-dom";


import Landing from '../screens/startup/landing'
import Contact from '../screens/startup/contact'
import About from '../screens/startup/about'
import NavbarStart from '../components/navbarStart'
import NavbarLandingMobile from '../components/NavBarLanding/navLandingMobile'


const RouterStartup = ()=>{
    const[responsive,setResponsive] = useState(false)
    const[login,setLogin] = useState(false)
    useEffect(()=>{
      loginCheck()
      if(window.innerWidth <= 600) setResponsive(true)
    },[])
    
    const loginCheck = ()=>{
      let login = localStorage.getItem('login')
      if(login){
        setLogin(true)
      }
    }
    return (
        <>
        {(!login)?
        responsive?<NavbarLandingMobile/>:<NavbarStart/>
        :null
        }
        {/* {responsive?<NavbarLandingMobile/>:<NavbarStart/>} */}
        <Switch>
          <Route exact path='/' component={Landing}/>
          <Route exact path='/about' component={About}/>
          <Route exact path='/contact' component={Contact}/>
        </Switch>
        </>
    )
}

export default withRouter(RouterStartup)


