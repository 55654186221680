import React,{useState,useEffect, useRef, useCallback} from 'react'
import '../../styles/jobView.css'
import '../../styles/responsive.css'
import Next from '../../assets/images/next.png'
import Close from '../../assets/images/close.png'
import Right from '../../assets/images/right-arrow.png'
import Profession from '../../assets/images/profession.png'
import { Redirect,Link,withRouter } from "react-router-dom";
import {useAuth} from '../../service/authContext'
import Modal from 'react-modal'
import Skeleton from 'react-loading-skeleton'
import { BeatLoader } from 'react-spinners';
import {routesRc} from '../../api/routes'
import axios from 'axios'



function Archive(props){
    const[showDetails,setDetails] = useState()
    const[modalIsOpen,setModalIsOpen] = useState(false)
    const[responsive,setResponsive] = useState(false)
    const[loadingJobs,setLoadingJobs] = useState(true)
    const[loadMore,setLoadMore] = useState(false)
    const[endList,setEndList] = useState(false)
    const[archives,setArchives] = useState([])



    const {loginStateRc} = useAuth()
    // const {fetchArchives,archives,loadingJobs,archiveJob,loadMore,endList} = RecruiterStore()

    const page = useRef(1)
    const observer = useRef()

    const fetchArchives = async (page)=>{
        try{
            if(!endList){
            setLoadMore(true)
            let recruiterId = await localStorage.getItem('rc')
            let token = await localStorage.getItem('tkRc')
            let route = routesRc.jobs

            let req = await axios.get(`${route}/${recruiterId}?page=${page}&status=${"archived"}`,{
                headers:{
                    "Content-Type":'application/json',
                    'authorization':`Bearer ${token}` 
                }
            }).then(res=>{
                if(res.status === 200 || res.status === 'OK'){
                    
                    if(res.data.length === 0){
                        setEndList(true)
                    } 

                    setTimeout(()=>{
                        setLoadingJobs(false)
                    },2000)
                    setLoadMore(false)
                    setArchives(archives=>[...archives, ...res.data])


                }
            }).catch(e=>{
                alert("Something has went wrong, Please try after some time")  

            })
          }
        }catch(e){
            alert("Something has went wrong, Please try after some time")  

        }
    }
    
    const lastJob = useCallback(node =>{

      if(observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting){
          if(endList === false){

            page.current = page.current + 1
            fetchArchives(page.current)
          }else{
            return null
          }
        }
      })

      if(node) observer.current.observe(node)
    },[])



    useEffect(()=>{
      if(window.innerWidth <= 600) setResponsive(true)
      fetchArchives(page.current)
    },[])


    
    const jobDescription = (item)=>{
      return <div className="ll">
      <div className="jobRow">
      {responsive?      <button onClick={()=>setModalIsOpen(false)}>
        <img src={Close} className="jobIcon"/>  
      </button> : null}  
      
      </div>  

      <div className="jobContainer">
      <div className="jobRow">
          <h4 className="jobTitle">{item.title}</h4>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Listed :</h4>
          <p className="certPara">{item.created}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Profession :</h4>
          <p className="certPara">{item.profession}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Industry :</h4>
          <p className="certPara">{item.industry}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Salary :</h4>
          <p className="certPara">{item.pay}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Location :</h4>
          <p className="certPara">{item.location}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">About :</h4>
        </div>
        <div className="jobRow">
          <p className="certPara">{item.about}</p>
        </div>

        <div className="btn-row">
        <Link
          to={{
              //Path to applications page
              pathname: '/recruiter/jobs/applications',
              //Job data is passed so it can be displayed on the application's page
              state: {
                Job: item,
              },
          }}>
          <div className="application">VIEW JOB APPLICATIONS<img src={Right} className="jobIcon"/></div>
      </Link>
        </div>


     </div>
     <div>
     </div>
     </div>
     
    }


    const showJobDetails = (item) =>{
      setDetails(item)
      if(responsive) setModalIsOpen(true)
 
    }

    const showJobs = (value)=>{
        const data = value
        if(value !== undefined){
        return data.map((item,index)=>{

          if(data.length === index +1){

            return <a className="listContainer" ref={lastJob} key={index} onClick={()=>showJobDetails(item) }>
                <div className="listHeading">
                    <div className="listSelect listRow-J">
                      <h4>{item.title.substring(0,40)}..</h4>
                      <img src={Next} className="jobIcon"/>
                    </div>
                    <div className="listRow-J">
                      <div className="row">
                        <img src={Profession} className="jobIcon"/>
                        <p>{item.profession}</p> 
                      </div>
                    </div>
                </div>
            </a>
          }else{
            return <a className="listContainer" key={index} onClick={()=>showJobDetails(item) }>
            <div className="listHeading">
                <div className="listSelect listRow-J">
                  <h4>{item.title.substring(0,40)}..</h4>
                  <img src={Next} className="jobIcon"/>
                </div>
                <div className="listRow-J">
                  <div className="row">
                    <img src={Profession} className="jobIcon"/>
                    <p>{item.profession}</p> 
                  </div>
                </div>
            </div>
            </a>

          }
        })
      }
    }

    return loginStateRc?(
        <div>
           <div className="main">

             <div className="mainContainer">

             <div className="container">

                {(!loadingJobs)?showJobs(archives):
                   <div className="listContainer">
                      <div className="listHeading">
                          <div className="skeleton">
                          <Skeleton height={25} width={`90%`}/> 
                          </div>
                          <div className="skeleton">
                          <Skeleton height={15} width={`25%`}/> 
                          </div>
                      </div>
                  </div>
                }

                <div style={{margin:'20px'}} className="row">
                  <BeatLoader
                     size={13}
                     color={"#7B8B8C"}
                     loading = {loadMore}
                   />

                   {(endList)?(<div> <p className="row">END</p></div>) : null}
                </div>


             </div>

             <div className="jobDetails">

                {(showDetails)?jobDescription(showDetails):
                     <div className="job-skeleton">
                     <div>     
                     <Skeleton width={`90%`} height={40} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`30%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`45%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`40%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`50%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`35%`} height={26} duration={0}/>
                     </div>
                     <div >     
                     <Skeleton width={`16%`} height={26} duration={0}/>
                     </div>
                     <div className="abt-skeleton">     
                     <Skeleton width={`90%`} height={20} count={2} duration={0}/>
                     <Skeleton width={`35%`} height={20} duration={0}/>
                     <Skeleton width={`90%`} height={20} count={2} duration={0}/>
                     <Skeleton width={`70%`} height={20} duration={0}/>
                     </div>
                   </div>
                }


             </div>
             <Modal isOpen={modalIsOpen}>
                {(showDetails !== undefined)?jobDescription(showDetails):null}
              </Modal>
             </div>

           </div>
        </div>

    ):<Redirect to='/recruiter/login'/>
}

export default withRouter(Archive)
