import React,{useState} from 'react'
import {withRouter,Redirect, Link} from 'react-router-dom'
import {useAuth} from '../../service/authContext'
import { BeatLoader } from 'react-spinners';
import Company from '../../assets/images/company.png'

//***TODO****//
//TESTING NEEDS TO BE DONE FOR THE USER AUTHENTICATION AND REDIRECT TO HOME PAGE
//ONLY AFTER TOKEN IS SAVED

function LoginCp(props){
 const[email,setEmail] = useState('')
 const[password,setPassword] = useState('')
 const {loginStateCp,loginCp,loading} = useAuth(); 
//  const[loading,setLoading] = useState(false)

 const onChangeEmail = (e)=>{
   setEmail(e.target.value)
 }
 const onChangePassword = (e)=>{
   setPassword(e.target.value)
 }


 return !loginStateCp ? (
     <div className="main">
       <div className="login">
        <div className="containerLogin">
          <div className="row">
              <img src={Company} className="jobIcon-R" style={{backgroundColor:'#0b5dbd',padding:5}}/>
              <h3 className="forgot-header">Company Login</h3>
          </div> 
          <div className="row">
            <h3>Email</h3>  
          </div>   
          <div className="row">
           <input 
           className="input"
           name="email"
           placeholder="  Enter your email"
           color={'#d3d3d3'}
           value={email}
           onChange={onChangeEmail}/>
          </div>
          <div className="row">
           <h3>Password</h3>
          </div>
          <div className="row">
           <input 
           className="input"
           placeholder="  Enter your password"
           type='password'
           name="password"
           value={password}
           onChange={onChangePassword}
           />
          </div>
          <div className="row" style={{margin:0,padding:0}}> 
            <Link to="/accounts/forgot/company">
             <p className="forgot-para">Forgot Password</p>
             </Link>
          </div>
          <div className="row">
          <button onClick={(e)=>{

            loginCp(email,password)}}>LOGIN</button>
          </div>
          <div className="row">
            <Link to='/company/signup'>
             <p style={{fontSize:8}}>Don't have an Account? <p style={{color:'#0b5dbd',fontWeight:'bold'}}>Register now</p></p>
            </Link>
          </div>

        </div>
        <span className="loader">
          <BeatLoader
          size={12}
          color={"#7B8B8C"}
          loading = {loading}
        />
        </span>
       </div>  
     </div>
 ) : ( <Redirect to='/company'/> )
}

export default withRouter(LoginCp)