const industries = [
{ value: 'Construction', label: 'Construction' },
{ value: 'Unskilled', label: 'Unskilled' },
{ value: 'Hospitality', label: 'Hospitality' },
{ value: 'Logistics', label: 'Logistics' },
{ value: 'Events', label: 'Events' },
{ value: 'Office Staff', label: 'Office Staff' },
{ value: 'Drivers/Riders', label: 'Drivers/Riders' },
{ value: 'Others', label: 'Others' } 
]
module.exports.industries = industries;


const professions = [ 
{ value: 'Supervisor', label: 'Supervisor' },
{ value: 'Ductman', label: 'Ductman' },
{ value: 'Electrician', label: 'Electrician' },
{ value: 'Plumber', label: 'Plumber' },
{ value: 'Mason', label: 'Mason' },
{ value: 'Helper', label: 'Helper' },
{ value: 'Carpenter', label: 'Carpenter' },
{ value: 'Steel Fixer', label: 'Steel Fixer' },
{ value: 'Gypsum Carpenter', label: 'Gypsum Carpenter' },
{ value: 'Painter', label: 'Painter' },
{ value: 'Spray Painter', label: 'Spray Painter' },
{ value: 'Shuttering Carpenter', label: 'Shuttering Carpenter' },
{ value: 'Multi Technician', label: 'Multi Technician' },
{ value: 'Finishing Carpenter', label: 'Finishing Carpenter' },
{ value: 'Pipe Fitter', label: 'Pipe Fitter' },
{ value: 'Furniture Carpenter', label: 'Furniture Carpenter' },
{ value: 'Insulator', label: 'Insulator' },
{ value: 'HSE Officer', label: 'HSE Officer' },
{ value: 'Welder', label: 'Welder' },
{ value: 'Factory Helper', label: 'Factory Helper' },
{ value: 'Construction Helper', label: 'Construction Helper' },
{ value: 'Porter', label: 'Porter' },
{ value: 'Cleaner', label: 'Cleaner' },
{ value: 'Car Cleaner', label: 'Car Cleaner' },
{ value: 'Room Attendant', label: 'Room Attendant' },
{ value: 'Kitchen Steward', label: 'Kitchen Steward' },
{ value: 'Housekeeping Staff', label: 'Housekeeping Staff' },
{ value: 'Receptionist', label: 'Receptionist' },
{ value: 'Front Office Staff', label: 'Front Office Staff' },
{ value: 'Buggy Rider', label: 'Buggy Rider' },
{ value: 'Waiter', label: 'Waiter' },
{ value: 'Cashier', label: 'Cashier' },
{ value: 'Production Line Staff',
  label: 'Production Line Staff' },
{ value: 'HSE', label: 'HSE' },
{ value: 'Foreman', label: 'Foreman' },
{ value: 'Warehouse Assistant', label: 'Warehouse Assistant' },
{ value: 'Loader', label: 'Loader' },
{ value: 'Guest Relation', label: 'Guest Relation' },
{ value: 'Ride Operator', label: 'Ride Operator' },
{ value: 'Host', label: 'Host' },
{ value: 'Pavillion Host', label: 'Pavillion Host' },
{ value: 'Pavillion Helper', label: 'Pavillion Helper' },
{ value: 'Traffic Marshal', label: 'Traffic Marshal' },
{ value: 'Tour Guide', label: 'Tour Guide' },
{ value: 'Response Team', label: 'Response Team' },
{ value: 'Administrator', label: 'Administrator' },
{ value: 'Office Boy', label: 'Office Boy' },
{ value: 'Accountant', label: 'Accountant' },
{ value: 'HR Executive', label: 'HR Executive' },
{ value: 'Driver', label: 'Driver' },
{ value: 'Light Vehicle Driver', label: 'Light Vehicle Driver' },
{ value: 'Heavy Vehicle Driver', label: 'Heavy Vehicle Driver' },
{ value: 'Fork Lifter', label: 'Fork Lifter' },
{ value: 'Security Guard', label: 'Security Guard' },
{ value: 'Security Manager', label: 'Security Manager' },
{ value: 'Others', label: 'Others' } 
]

module.exports.professions = professions