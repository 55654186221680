import React,{useState,useEffect} from 'react'
import '../../styles/jobView.css'
import '../../styles/responsive.css'
import '../../styles/skeleton.css'
import Next from '../../assets/images/next.png'
import Profession from '../../assets/images/profession.png'
import Close from '../../assets/images/close.png'
import Location from '../../assets/images/location.png'
import Modal from 'react-modal'
import { Redirect,withRouter } from "react-router-dom";
import {useAuth} from '../../service/authContext'
import {CompanyStore} from '../../service/companyStore'
import Skeleton from 'react-loading-skeleton'


function JobsViewCp(props){

    const[showDetails,setDetails] = useState()
    const[selected,setSelected] = useState()
    const[responsive,setResponsive] = useState(false)
    const[modalIsOpen,setModalIsOpen] = useState(false)
    const[storeJobs,setStore] = useState()


    useEffect(()=>{
      if(window.innerWidth <= 600) setResponsive(true)
      fetchJobs()
      setStore(jobs)
    },[])
    const{loginStateCp} = useAuth()
    const{jobs,fetchJobs,loadingJobs} = CompanyStore()

    const jobDescription = (item)=>{
      return <div className="ll" >
      <div className="jobContainer">
      {responsive?      <button onClick={()=>setModalIsOpen(false)}>
        <img src={Close} className="jobIcon-R"/>  
      </button> : null}    
      <div className="jobRow">
          <h4 className="certHeading jobTitle">{item !== undefined? item.title : null}</h4>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Listed :</h4>
          <p className="certPara">{item? item.created : <Skeleton />}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Profession :</h4>
          <p className="certPara">{item? item.profession : <Skeleton />}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Industry :</h4>
          <p className="certPara">{item? item.industry : <Skeleton />}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Salary :</h4>
          <p className="certPara">{item? item.pay : <Skeleton />}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Location :</h4>
          <p className="certPara">{item? item.location : <Skeleton />}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">About :</h4>
        </div>
        <div className="jobRow">
          <p className="certPara">{item? item.about : <Skeleton count={10}/>}</p>
        </div>

     </div>
     <div>
     </div>
     </div>
     
    }
    const showWindow = (item)=>{
      setDetails(item)
      setModalIsOpen(true)
   }

    const showJobs = (value)=>{

        const data = value
        const showJobDetails = (item) =>{
          setDetails(item)
        }

        return data.map((item,index)=>{
            return <div className="listContainer" onClick={()=>!responsive? showJobDetails(item):showWindow(item)}>
                <div className="listHeading">
                    <div className="listSelect listRow-Cp">
                      <h4>{item.title.substring(0,40)}..</h4>
                      <img src={Next} className="jobIcon"/>
                    </div>
                    <div className="listRow-Cp">
                      <div className="row">
                        <img src={Profession} className="jobIcon"/>
                        <p>{item.profession}</p> 
                      </div>
                      <div className="row">
                        <img src={Location} className="jobIcon"/>
                        <p>{item.location}</p> 
                      </div>
                    </div>
                </div>
            </div>
        })
    }

    return loginStateCp? (
        <div>
           <div className="main">

             <div className="mainContainer">

             <div className="container">
                {(!loadingJobs)?showJobs(jobs):
                <div className="listContainer">
                <div className="listHeading">
                    <div className="skeleton">
                    <Skeleton height={25} width={`90%`}/> 
                    </div>
                    <div className="skeleton">
                    <Skeleton height={`100%`} width={`25%`}/> 
                    </div>
                </div>
            </div>}                
             </div>

             <div className="jobDetails">
                {(showDetails)?jobDescription(showDetails):
                          <div className="job-skeleton">
                           <div>     
                           <Skeleton width={`90%`} height={40} duration={0}/>
                           </div>
                           <div>     
                           <Skeleton width={`30%`} height={22} duration={0}/>
                           </div>
                           <div>     
                           <Skeleton width={`45%`} height={22} duration={0}/>
                           </div>
                           <div>     
                           <Skeleton width={`40%`} height={22} duration={0}/>
                           </div>
                           <div>     
                           <Skeleton width={`50%`} height={22} duration={0}/>
                           </div>
                           <div>     
                           <Skeleton width={`35%`} height={22} duration={0}/>
                           </div>
                           <div >     
                           <Skeleton width={`16%`} height={22} duration={0}/>
                           </div>
                           <div className="abt-skeleton">     
                           <Skeleton width={`90%`} height={15} count={2} duration={0}/>
                           <Skeleton width={`35%`} height={15} duration={0}/>
                           <Skeleton width={`90%`} height={15} count={5} duration={0}/>
                           <Skeleton width={`70%`} height={15} duration={0}/>
                           </div>
                         </div>
                }
                {/* {jobDescription(showDetails)} */}
             </div>
             <Modal isOpen={modalIsOpen}>
                {(showDetails)?jobDescription(showDetails):null}
                {/* {jobDescription(showDetails)} */}
              </Modal>
             </div>

           </div>
        </div>

    )
    : (<Redirect to="/company"/>)
}

export default withRouter(JobsViewCp)
