import React from "react";
import { Switch, Route,withRouter } from "react-router-dom";

import StartUp from '../screens/startup/startup'
import LoginCp from '../screens/company/loginCp'
import LoginRc from '../screens/recruiter/loginRc'

const RouterLogin = ()=>{
    return(
        <>
        <Switch>
         <Route exact path="/login" component={StartUp}/>  
         <Route exact path="/company/login" component={LoginCp}/>  
         <Route exact path="/recruiter/login" component={LoginRc}/>

        </Switch>  
        </>
    )
}

export default withRouter(RouterLogin)