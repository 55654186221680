import React,{createContext,useState} from 'react'
import history from '../components/history'
import axios from 'axios'
import {routesCp} from '../api/routes'


const CompanyData = createContext()

const CompanyProvider = (props)=>{

    const[loadingJobs,setLoadingJobs] = useState(true)


    //Company Jobs
    const[jobs,setjobs] = useState([])
    //Company Recruiters    
    const[recruiters,setRecruiters] = useState()
    const[recruiter,setRecruiter] = useState('')
    //Company Profile
    const[edit,setEdit] = useState(false)
    const[company,setCompany] = useState({})
    const[image,setImage] = useState()
    const[imgUrl,setUrl] = useState()
    const[responsive,setResponsive] = useState(false)




    // useEffect(()=>{
    //   if(window.innerWidth <= 700) setResponsive(true)   
    //   let login = localStorage.getItem('login')
    //   if(login === 'Company'){
    //       fetchCompanyProfile()
    //   }
    // },[])

    //Fetching Company Profile
    const fetchCompanyProfile = async()=>{
        try{
           let companyId = await localStorage.getItem('cp')
           let token = await localStorage.getItem('tkCp') 
           let route = routesCp.profile
           const req = await axios.get(`${route}/${companyId}`,{
            headers:{
                "Content-Type":'application/json',
                'Accept' : 'application/json',
                "authorization":`Bearer ${token}` 
            }
        }).then(res=>{
               if(res.status === 200){
                   setUrl(res.data.photo)
                   setCompany(res.data)
               }
           })
        }catch(e){
  
        }
    }

    //Editing Company Information
    const editInfoCp = async (data)=>{
        try{ 
            console.log("Company info edit",data)
            let companyId = await localStorage.getItem('cp')
            let token = await localStorage.getItem('tkCp') 
            let route = routesCp.profile
            const req = await axios.patch(`${route}/${companyId}`,data,{
                headers:{
                    "Content-Type":'application/json',
                    'authorization':`Bearer ${token}` 
                }
            })
            .then(res=>{

              if(res.status === 200 || res.status === 'OK'){
                setCompany(data)
                setEdit(false)
                alert("Company profile information updated")
              }else if(res.status === 400 || res.status === 500){
                console.log("Bad request kate")
                alert("Something has went wrong, Please try after some time")  

              }
            })
        }catch(e){
            console.log("Bad request kate",e)

            alert("Something has went wrong, Please try after some time")  
        }
    }
    
    //Company's Logo 
    const companyLogo = async (data)=>{
        try{
            let companyId = await localStorage.getItem('cp')
            let token = await localStorage.getItem('tkCp') 
            let route = routesCp.addLogo
            const reqData = new FormData()
            await reqData.append('logoCp',data)
            let req = await axios.post(`${route}/${companyId}`,reqData,{
                headers:{
                    "Content-Type":"multipart/form-data", 
                    'authorization':`Bearer ${token}` 
                }
            })
             .then(res=>{
                 if(res.status === 200 && res.data === "Logo Updated"){
                     setUrl(res.data.Url)
                     alert("Company Logo has been Updated")
                     return history.push('/company')
                 }
             }) 
        }catch(e){

            alert("Something has went wrong, Please try after some time") 
            return history.push('/company')


        }
    }

    //Fetching all the jobs in the Company's name
    const fetchJobs =async ()=>{
        let companyId = await localStorage.getItem('cp')
        let token = await localStorage.getItem('tkCp') 
        let route = routesCp.jobs

        let req = await axios.get(`${route}/${companyId}`,{
            headers:{
                "Content-Type":'application/json',
                'authorization':`Bearer ${token}` 
            }
        }).then(Response=>{
            setTimeout(()=>{
                setLoadingJobs(false)

            },2000)
            setjobs(Response.data)  
            console.log(jobs.length)
        }).catch(e=>{
            alert("Something has went wrong, Please try after some time")  
        })
    }

    //Fetching all the recuiters in the Company's profile
    const fetchRecruiters = async ()=>{
        try{
            let companyId = await localStorage.getItem('cp')
            let token = await localStorage.getItem('tkCp') 
            let route = routesCp.recruiters

          let req = await axios.get(`${route}/${companyId}`,{
            headers:{
                "Content-Type":'application/json',
                "authorization":`Bearer ${token}` 
            }
        }).then(res=>{
             if(res.status === 200 || res.status === 'OK'){
                setRecruiters(res.data)
                setTimeout(()=>{
                //   setLoadingRec(false)
                },1500)
             } 
          })
            
        }catch(e){
            alert("Something has went wrong, Please try after some time") 
        }
    }
    
    // const addRecruiter = async (data)=>{
    //     try{
    //         let userData = {
    //             email: data.email,
    //             fname: data.fname,
    //             mname:data.mname,
    //             lname: data.lname,
    //             number: data.number,
    //             password: data.password,
    //             post: data.post
    //           }
    //         let route = routesCp.addRecruiter
    //         let token = await localStorage.getItem('tkCp') 
    //         let company = await localStorage.getItem('cp')

    //         let req = await axios.post(`${route}/${company}`,userData,{
    //             headers:{
    //               "Content-Type":'application/json',
    //               'authorization':`Bearer ${token}` 
    //             }
    //           })
    //           .then(res=>{
    //               if(res.status === 200 || res.data.Recruiter !== undefined){
    //                 setRecruiter(res.data.Recruiter)
    //                 setUpload(true)
    //               }else{
    //                 if(res.status === 400){
    //                 alert("Something has went wrong, Please try after Sometime")
    //                 }
    //               }
    //             })

    //     }catch(e){

    //     }
    // }
    

    //Delete a recruiter from Company's profile
    const deleteRecruiter = async (recruiter)=>{
        try{
            let companyId = await localStorage.getItem('cp')
            let token = await localStorage.getItem('tkCp') 
            let route = routesCp.recruiters
            
           let req = await axios.delete(`${route}/${companyId}/${recruiter}`,{
            headers:{
                "Content-Type":'application/json',
                'authorization':`Bearer ${token}` 
            }
        }).then(res=>{
            //  setReload(true)
            if(res.status === 200 && res.data === "Recruiter has been deleted"){
            alert("The recruiter has been Removed")
            window.location.reload(false)
            }
           })
        }catch(e){
    
        }
    }

    const values = {
        company,
        jobs,
        fetchJobs,
        fetchRecruiters,
        fetchCompanyProfile,
        deleteRecruiter,
        // addRecruiter,
        recruiters,
        recruiter,
        // upload,
        editInfoCp,
        companyLogo,
        image,
        imgUrl,
        edit,
        setEdit,
        responsive,
        loadingJobs
    }
    return <CompanyData.Provider value={values} {...props} />;
}

const CompanyStore = ()=> React.useContext(CompanyData)
export {CompanyProvider,CompanyStore}