import React, { useEffect,useState } from 'react'
import './componentStyles.css'
import Logo from '../assets/images/logosplash.png'
import Img from '../assets/images/image.png'
import Logout from '../assets/images/logout.png'
import history from '../components/history'
import{CompanyStore} from '../service/companyStore'
import {useAuth} from '../service/authContext'
import {Link } from 'react-router-dom'






function NavBarCp(props){
    const{logoutCp,loginStateCp} = useAuth()
    // const{company,fetchCompanyProfile} = CompanyStore()
    const[company,setCompany] = useState()
    const[name,setName] = useState('')
    const[photo,setPhoto] = useState('')

    useEffect(()=>{
         
        getProfile()
        
     
    },[])

    const getProfile = async()=>{
        
        let data = await localStorage.getItem('profile')
        if(data){
         let profile = await JSON.parse(data)   
         await setName(profile.name)
         await setPhoto(profile.photo)
        }

    }
    return loginStateCp? (
        <nav className="navbar">

              <div className="profile">
              {/* // <img src={(company || company !== null || company !== undefined || company.photo !== undefined || company.photo !== '' )? company.photo : Img} className="profilePic"/> */}
               <img src={( photo  )?  photo : Img} className="profilePic"/>
               <Link to="/company/profile">
              {/* // <h3 className="name">{(company && company !== null || company !== undefined && company.name && company.name !== undefined || company.name !== '')? company.name :"Edit Profile"}</h3> */}
                 <h3 className="name">{( name )? name :"Edit Profile"}</h3>
               </Link>
             </div>


            <Link to="/company">
              <img src={Logo} className="logoMain"/>
            </Link>

            <div className="navMenu">


            <a className="logout" onClick={logoutCp}>
                <h3>Logout</h3>
                <img className="add" src={Logout}/>
            </a>

            </div>

        </nav>
    ) : (<div></div>)
}
export default NavBarCp