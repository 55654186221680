import React,{useState} from 'react'


import {withRouter,Redirect} from 'react-router-dom'
import {useAuth} from '../../service/authContext'
import{Formik,Form,useField} from 'formik'
import * as yup from 'yup'

import { BeatLoader } from 'react-spinners';


const MyTextInput = ({label,...props})=>{
  const[field,meta] = useField(props);
  return(
    <>
   <div className="row"> 
    <input className="input" {...field} {...props} />
   </div> 
  
   <div className="row-error"> 
    {meta.touched && meta.error ? (
     <p className="error">*{meta.error}</p>
    ):null}
   </div> 
    </>
  );
};

const validationSchema = yup.object({
  email:yup.string().min(6,"Please enter a valid email with minimum 6 characters").max(250,"Email cannot exceed 250 characters").required("Please enter Email"),
  password:yup.string().min(6, "Password should be minimum of 6 characters").max(150,"Password must not exceed 150 characters"),
  confirmPassword:yup.string()
  .oneOf([yup.ref('password'), null], 'Passwords do not match'),
})

function SignUpCp(props){

 const {loginStateCp,signUpCP} = useAuth(); 
 const[loading,setLoading] = useState(false)
 
 const submit = (values)=>{
   setLoading(true)

   let email = values.email
   let password = values.password
   signUpCP(email,password)
 }



 return !loginStateCp ? (
     <div className="main">
       <div className="login">
        <div className="containerLogin">
        <Formik
         initialValues={{
           email:'',
           password:''
         }}
         validationSchema={validationSchema}
         onSubmit={(values)=>{
           submit(values)
         }}
        > 
          <Form>
          <div className="row">
            <h3>Email</h3>  
          </div>   
          <MyTextInput 
            name="email"
            type="text"
            placeholder="Enter your Email"
          />
          <div className="row">
           <h3>Password</h3>
          </div>
          <MyTextInput 
            name="password"
            type="password"
            placeholder="Enter your Password"
          />
          <MyTextInput 
            name="confirmPassword"
            type="password"
            placeholder="Confirm your Password"
          />
          <div className="row">
          <button type="submit" className="button">REGISER</button>
          </div>
          </Form>
        </Formik>
          <BeatLoader
              size={12}
              color={"#7B8B8C"}
              loading = {loading}
           />
        </div>
       </div>  
     </div>
 ) : ( <Redirect to='/company'/> )
}

export default withRouter(SignUpCp)