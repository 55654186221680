import React, { useEffect, useState } from 'react';
import './App.css';
import {  Route,withRouter,Router} from 'react-router-dom'
import history from './components/history'
import './styles/responsive.css'


import './styles/responsive.css'
import StartUp from './screens/startup/startup'
import RouterRc from './navigation/recruiterNavigation'
import RouterCp from './navigation/companyNavigation'
import RouterStartup from './navigation/startupNavigation'
import RouterLogin from './navigation/loginNavigation'
import RouterForgot from './navigation/forgotNavigation'
import LoginCp from './screens/company/loginCp'
import LoginRc from './screens/recruiter/loginRc'
import Landing from './screens/startup/landing'
import About from './screens/startup/about'
import Contact from './screens/startup/contact'
import ProfileInfoCp from './screens/company/profileInfoSignup'

import { AuthProvider} from './service/authContext'
import {RecruiterProvider} from './service/recruiterStore'
import {CompanyProvider} from './service/companyStore'

function App() {
  
  useEffect(()=>{
   redirectCheck()
  },[])

  const redirectCheck = async()=>{
    const login =await localStorage.getItem('login')
    
    if(login === 'Company'){
      return history.push('/company')
    }else if(login === 'Recruiter'){
      return history.push('/recruiter')
    }else if(login === null || login === undefined){
      return null
    }
  } 

  return (
      <div className="App">

        <AuthProvider>

          <Router history={history}>
            <RouterForgot/>
            <RouterStartup/>
            <RouterLogin/>
            <CompanyProvider>
              <RouterCp/>
            </CompanyProvider>
 
             <RecruiterProvider>
              <RouterRc/>
             </RecruiterProvider>  

          </Router>
        </AuthProvider>
        
      </div>
  );
}

// export default App;
export default App
