import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Link,withRouter} from 'react-router-dom'
import BurgerLanding from './burgerLanding';
import LogoMain from '../../assets/images/logomain.png'
import Login from '../../assets/images/login-white.png'

import '../../styles/responsive.css'


const NavbarLandingMobile = ()=>{
    return(
        <nav className="nav-start">
                 <div className="row">

        <Link to="/login">
          <div className="login-landing" >
              <h4>Login</h4>
              <img src={Login}/>
          </div>
        </Link>
        </div>   
         <BurgerLanding/>
        </nav>
        
    )
}

export default NavbarLandingMobile