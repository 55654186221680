import React,{useEffect} from 'react'
import '../../styles/recruiters.css'
import '../../styles/applications.css'

import Add from '../../assets/images/add.png'
import Delete from '../../assets/images/delete.png'
import Profession from '../../assets/images/designation.png'
import Email from '../../assets/images/email-black.png'
import Phone from '../../assets/images/phone-black.png'
import { Redirect,Link,withRouter } from "react-router-dom";

import {CompanyStore}  from '../../service/companyStore'
import {useAuth} from '../../service/authContext'
import Img from '../../assets/images/image.png'
import Skeleton from 'react-loading-skeleton'
import history from '../../components/history'





function Recruiters(){
  const{loginStateCp} = useAuth()
  const{fetchRecruiters,deleteRecruiter,recruiters} = CompanyStore()
      
    useEffect(()=>{
       if(loginStateCp){
        fetchRecruiters()
       }else{
         return history.push('/company')
       }
    },[])

    

    const showRecruiters = (value)=>{

        const data = value
        return data.map((item)=>{

            return <div className="profileContainer">
                <div className="listHeading-R">
                    <div className="listRow-Dp">
                        <img className="profile-R" src={(item.photo === '')? Img : `${item.photo}`}/>
                    </div>
                    <div className="listRow-R">
                      <h4>{item.name}</h4>
                    </div>
                    <div className="listRow-R">
                      <div className="row">
                        <img src={Profession} className="jobIcon-R"/>
                        <p>{item.designation}</p> 
                      </div>
                    </div>
                    <div className="listRow-R">
                      <div className="row">
                        <img src={Email} className="jobIcon-R"/>
                        <p>{item.email}</p> 
                      </div>
                    </div>
                    <div className="listRow-R">
                      <div className="row">
                        <img src={Phone} className="jobIcon-R"/>
                        <p>{item.number}</p> 
                      </div>
                    </div>
                    <div className="listRow-R">
                      <button id="remove" onClick={()=>deleteRecruiter(item.id)} className="remove">
                        <img src={Delete} className="jobIcon-R" />
                      </button>
                    </div>
                </div>
            </div>
        })
    }
    if(recruiters === null || recruiters === undefined){
      return(
          <div className="main">
            <div className="mainContainer-R">
            <div className="profileContainer">
             <Skeleton circle={true} height={100} width={100}/>
             <div className="profile-skeleton">
               <Skeleton width={100} height={20} />
             </div>
             <div className="profile-skeleton">
               <Skeleton width={75} height={20} />
             </div>
             <div className="profile-skeleton rm">
               <Skeleton width={30} height={20} />
             </div>
         
            </div>
            </div>
          </div>
      )
    }
    return loginStateCp? (

           <div className="main">

             <div className="mainContainer-R">
             {showRecruiters(recruiters)}

             {(recruiters.length <=4)?
             <Link to="/company/recruiters/add">
             <div className="profileContainer add">
               <img className="add-Img" src={Add}/>
               <div className="row">
                 <h3 className="add-H">ADD RECRUITER</h3>
               </div>
             </div>
             </Link> 
             : null }
             </div>

           </div>


    ) : (< Redirect to="/company" />)
    
}

export default withRouter(Recruiters)
