
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect,withRouter,useParams } from "react-router-dom";
import ForgotAp from '../screens/forgot/forgot'
import ForgotUsers from '../screens/forgot/forgotUsers'
import ResetPasswordAp from '../screens/forgot/reset'

const RouterForgot = ()=>{
    return(
        <Switch>  
          <Route exact path='/accounts/forgot' component={ForgotAp}/>
          <Route exact path='/accounts/forgot/:user' children={ForgotUsers} />
          <Route exact path='/accounts/reset/:user/:id/:token' children={ResetPasswordAp} />
        </Switch>
    )
}

export default withRouter(RouterForgot)