import React from 'react';
import {withRouter} from 'react-router-dom';
import ctc from '../../assets/images/hd4.jpg';
import '../../styles/animated-bg.css';



const Contact = ()=>{
    const width = window.innerWidth
    return(
        <>
        {width <= 600? 
                <div className="contact-us test">
                <div>
                    
                </div>
                <div className="landing-about">
                    <h1>CONTACT DETAILS</h1>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h4>
                        Valuevestor Consultants
                        <br>
                        </br>
                        Ajman Free Zone
                        <br></br>
                        +971 50 561 6328
                        <br></br>
                        <br></br>
                        Business Bay
                        <br></br>
                        Dubai
                        <br></br>
                        <br></br>
                        efrontliners@valuevestor.co
                    </h4>
                </div>
            </div>

            :

            <div className="contact-us" style={{backgroundImage:`url(${ctc})`}}>
            <div>
                
            </div>
            <div className="landing-about">
                <h1>CONTACT DETAILS</h1>
                <br></br>
                <br></br>
                <br></br>
                <h4>
                    Valuevestor Consultants
                    <br>
                    </br>
                    Ajman Free Zone
                    <br></br>
                    +971 50 561 6328
                    <br></br>
                    <br></br>
                    Business Bay
                    <br></br>
                    Dubai
                    <br></br>
                    <br></br>
                    efrontliners@valuevestor.co
                </h4>
            </div>
        </div>
        }




        </>
    )
}

export default withRouter(Contact)