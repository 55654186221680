import React from 'react';
import {Link,withRouter} from 'react-router-dom'
import home from '../../assets/images/hd.jpg'


const Landing = ()=>{
   
    const width = window.innerWidth
   return(

    <div className='landing-main' >
      {width <= 600? 
             <div className="landing test">
             <div className="create-landing">
                 <div className="row">
                     <h1>Need a Frontliner ?</h1>
                 </div>
                 <div className="row">
                     <h2>HIRE NOW</h2>
                 </div>
                 <div className="row">
                  <Link to="/company/signup">
                      <div className="create-btn">
                          <h4>Create an Account</h4>
                          {/* <img src={Next}/> */}
                      </div>
                  </Link>
                 </div>
             </div>
         </div>
         :

         <div className="landing-main" style={{backgroundImage:`url(${home})`}}>
         <div className="create-landing">
             <div className="row">
                 <h1>Need a Frontliner ?</h1>
             </div>
             <div className="row">
                 <h2>HIRE NOW</h2>
             </div>
             <div className="row">
              <Link to="/company/signup">
                  <div className="create-btn">
                      <h4>Create an Account</h4>
                      {/* <img src={Next}/> */}
                  </div>
              </Link>
             </div>
         </div>
     </div>
    }

    </div>
   )
}

export default withRouter(Landing)


