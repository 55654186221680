import React,{createContext,useEffect,useState} from 'react'
import history from '../components/history'
import axios from 'axios'
import {routesRc,routesCp} from '../api/routes'


const AuthContext = createContext()

const AuthProvider = (props) =>{

    const[loginStateCp,setLoginCp] = useState()
    const[tokenCp,setTokenCp] = useState('')
    const[company,setCompany] = useState({})
    const[signUp,setSignup] = useState(false)
    const[loading,setLoading] = useState(false)

    const[loginState,setLoginState] = useState()


    const[loginStateRc,setLoginRc] = useState()
    const[tokenRc,setTokenRc] = useState('')
    const[recruiter,setRecruiter] = useState({})



    useEffect(()=>{
      loginStateCheck()
    },[])

    const loginStateCheck = async()=>{
      const login =await localStorage.getItem('login')
      if(login === 'Company'){
        setLoginCp(true)
        setLoginState(login)
      }else if(login === 'Recruiter'){
        setLoginRc(true)
        setLoginState(login)
      }
      // else if(login === null || login === undefined){
      //   return history.push('/')
      // }
    }
    const loginRc = async (email,passwd)=>{
        await setLoading(true)
        let route = routesRc.login
        //  e.preventDefaut()
         const req = await axios.post(`${route}`,{
           "email":email,
           "password":passwd
         }).then((res)=>{
           if(res.status === 200 || res.data.Recruiter ){
            function setData(item){
              localStorage.setItem('rc',item.Recruiter)
              localStorage.setItem('tkRc',item.Access)
              localStorage.setItem('cpRc',item.Company)
              localStorage.setItem('login','Recruiter')
              localStorage.setItem('profile',JSON.stringify(item.Profile))
              setLoginRc(true)
             }

             setData(res.data)

             return  window.location.reload(false)
            }else{
            localStorage.removeItem('rc')
            localStorage.removeItem('tkRc')
            localStorage.removeItem('cpRc')
            localStorage.removeItem('login')
            localStorage.removeItem('profile')
           }
         }).catch(e=>{
           setLoading(false)
          alert(e.response.data)
          // return history.push('/')
          })
    }


    const logoutRc = async ()=>{
        
        function remove(){
          localStorage.removeItem('rc')
          localStorage.removeItem('tkRc')
          localStorage.removeItem('cpRc')
          localStorage.removeItem('cp')
          localStorage.removeItem('tkCp')
          localStorage.removeItem('login')
          localStorage.removeItem('profile')
          setTokenRc('')
          setLoginRc(false)
          setLoginCp(false)
        }
        await remove()
        return  window.location.reload(false)
      }
    


    const signUpCP = async (email,password)=>{
       try{
         let route = routesCp.signup

         const req = await axios.post(`${route}`,{
          "email":email,
          "password":password
        }).then(res=>{
           if(res.status === 200 || res.status === 'OK '){
             function setData (item){
               localStorage.setItem('cpSignup',item.Company)
               localStorage.setItem('tkCpSignup',item.Access)

              }
              setData(res.data)
              // setLoginCp(true)
              // setSignup(true)
              return history.push('/company/signup/profile')
           }
        }).catch(e=>{
          alert(`${email} ${e.response.data}`)
        }
        )

       }catch(e){

       }
    }
    const loginCp = async (email,passwd)=>{
        await setLoading(true)
        let route = routesCp.login
        // console.log(email,passwd)
        const req = await axios.post(`${route}`,{
          "email":email,
          "password":passwd
        }).then(async(res)=>{
          if(res.status === 200 && res.data.Company ){
           function setData (item){
             localStorage.setItem('cp',item.Company)
             localStorage.setItem('tkCp',item.Access)
             localStorage.setItem('login','Company')
             localStorage.setItem('profile',JSON.stringify(item.Profile))
             setLoginCp(true)
            }
            await setData(res.data)
            return  window.location.reload(false)
            

            // setTimeout(()=>{
            //   return history.push('/company/')
            // },1500)
         }else if(res.status === 203 || "Pending Approval"){
          localStorage.removeItem('cp')
          localStorage.removeItem('tkCp')
          alert("Your Profile is under review\nWe are reviewing your profile, Please try again later")
          // return history.push('/')
        }
        }).catch(e=>{
          setLoading(false)
          alert(e.response.data)

        })
     }
     


     const logoutCp = async()=>{

      function remove(){
        localStorage.removeItem('tkCp')
        localStorage.removeItem('cp')
        localStorage.removeItem('login')
        localStorage.removeItem('profile')
        setTokenCp('')
        setLoginCp(false)
      }
      await remove()
      //  return history.push('/')
      return  window.location.reload(false)

     }

    const authContextValue = {
        
        loginCp,
        loginStateCp,
        logoutCp,
        tokenCp,
        company,
        signUp,
        signUpCP,
 
        loading,

        loginStateRc,
        loginRc,
        logoutRc,
        tokenRc,
        recruiter,
    }

    return <AuthContext.Provider  value={authContextValue} {...props} />; 
}

const useAuth = ()=> React.useContext(AuthContext)

export {AuthProvider,useAuth}


