import React, { useEffect, useState } from 'react'
import './componentStyles.css'
import Logo from '../assets/images/logosplash.png'
import Img from '../assets/images/image.png'
import Logout from '../assets/images/logout.png'
import history from '../components/history'
import {useAuth} from '../service/authContext'
import {RecruiterStore} from '../service/recruiterStore'
import {Link } from 'react-router-dom'


function NavBarRc(props){
    const{logoutRc,loginStateRc} = useAuth()

    const[name,setName] = useState('')
    const[photo,setPhoto] = useState('')

    useEffect(()=>{
    
      getProfile()
      
    },[])

    const getProfile = async()=>{
        
      let data = await localStorage.getItem('profile')
      if(data){
       let profile = await JSON.parse(data)   
       await setName(profile.name)
       await setPhoto(profile.photo)
      }

  }
    return loginStateRc? (
        <nav className="navbar">
       
       <div className="profile">
       <img src={( photo  )?  photo : Img} className="profilePic"/>
    
       <h3 className="name">{( name )? name :"Edit Profile"}</h3>
               
             </div>

   

            <Link to="/recruiter">
              <img src={Logo} className="logoMain"/>
            </Link>

            <div className="navMenu">
            
       
            <a className="logout" onClick={logoutRc}>
                <h3>Logout</h3>
                <img className="add" src={Logout}/>
            </a>
      
            </div>
           
        </nav>
    ) : (null)
}
export default NavBarRc