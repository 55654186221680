import { FormikProvider } from 'formik'
import React from 'react'
import {Link,withRouter} from 'react-router-dom'
import LogoMain from '../assets/images/logomain.png'
import Login from '../assets/images/login-white.png'
import {useAuth} from '../service/authContext'

function NavbarStart(){
  const {loginStateCp,loginStateRc} = useAuth()
  return (!loginStateCp && !loginStateRc)?(
      <nav className="nav-start">
          <div className="logo-cont-landing">
              <Link to='/'>
              <img src={LogoMain} className="logo-landing"/>
              </Link>
          </div>
          <div className="row">
              <ul className="list-landing">
                  <li>
                    <Link to='/about'>
                    ABOUT
                    </Link>  
                  </li>
                  <li>
                    <Link to='/contact'>
                    CONTACT
                    </Link>  
                  </li>
              </ul>
              <Link to="/login">
                        <div className="login-landing" >
                            <h4>Login</h4>
                            <img src={Login}/>
                        </div>
              </Link>
          </div>
      </nav>
  ):null
}

export default NavbarStart