import React,{ useState} from 'react'
import {withRouter} from 'react-router-dom'
import{Formik,Form,useField} from 'formik'
import '../../styles/basicInfo.css'
import * as Yup from 'yup'
import Add from '../../assets/images/addPhoto.png'
import {industries} from '../../assets/enums/industries'
import Select from 'react-select';
import Photo from '../../assets/images/image.png'
import history from '../../components/history'
import { BeatLoader } from 'react-spinners';
import axios from 'axios'
import {routesCp} from '../../api/routes';
import { CompanyStore } from '../../service/companyStore'


const MyTextInput = ({ label, ...props }) => {

    const [field, meta] = useField(props);
    return (
      <>
  
        <label htmlFor={props.id || props.name}>{label}</label>
       
        <input className="text-input input-P" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };
  
  const MyTextArea = ({label,...props})=>{
    const[field,meta] = useField(props);
  
    return(
      <>
     <div className="row row-textarea"> 
     <div className='row '>
     <textarea className="jobAbt" {...field} {...props}>
      
      </textarea>
     </div>
  
      <div className="row-error"> 
      {meta.touched && meta.error ? (
       <p className="error">*{meta.error}</p>
      ):null}
     </div> 
     </div> 
    
  
      </>
    );
  };

  const ProfileInfoCp = ()=>{

    const[industry,setIndustry] = useState()
    const[image,setImage] = useState()
    const[edit,setEdit] = useState(true)
    const[loading,setLoading] = useState(false);

    // const { companyLogo } = CompanyStore();



             //Editing Company Information
             const profileInfoSignup = async (data)=>{
                try{  
          
                    let companyId = await localStorage.getItem('cpSignup')
                    let token = await localStorage.getItem('tkCpSignup') 
                    let route = routesCp.profile
                    const req = await axios.patch(`${route}/${companyId}`,data,{
                        headers:{
                            "Content-Type":'application/json',
                            'authorization':`Bearer ${token}` 
                        }
                    })
                    .then(res=>{
          
                      if(res.status === 200 || res.status === 'OK'){
                        setEdit(false)
                        setLoading(false)
                      }

                    }).catch(e=>{
                        alert("Something has went wrong, Please try after some time")  
                    })
                }catch(e){
                    alert(e)  
                }
              }
            
            //Company's Logo 
              const companyLogoSignup = async (data)=>{
                try{
                  let companyId = await localStorage.getItem('cpSignup')
                  let token = await localStorage.getItem('tkCpSignup') 
                  let route = routesCp.addLogo
                  const reqData = new FormData()
                  await reqData.append('logoCp',data)
                  let req = await axios.post(`${route}/${companyId}`,reqData,{
                      headers:{
                          "Content-Type":"multipart/form-data", 
                          'authorization':`Bearer ${token}` 
                      }
                  })
                   .then(res=>{
                       if(res.status === 200 && res.data === "Logo Updated"){
                        alert("Profile has been created\nThank you for registering with us\nWe will review your Profile, Once approved you will have access to the portal")
                        localStorage.removeItem('cpSignup')
                           localStorage.removeItem('tkCpSignup')
                       }
                   }).then(res => {
                                               
                    return history.push('/')
                   }) 
              }catch(e){
      
                  alert("Something has went wrong, Please try after some time") 
                  return history.push('/company')
      
      
              }
               }


    const picChange = (event)=>{
        const img = event.target.files[0]
        const imgUrl = URL.createObjectURL(img)

        setImage(imgUrl)
        companyLogoSignup(img)
    }

    const MySelect = (props) => {

        return (
            <>
            <label htmlFor={props.id || props.name}>{props.label}</label>
           <Select
             styles={customStyles}
             {...props}
             className="input"
             value={industries.filter(function(option) {
              return option.value === industry;
            })}
             onChange={value => handleChange(value)}
             options={props.options}
           />
        
            </>
        )
      }
      const handleChange = selectedOption => {
        setIndustry(selectedOption.value);
      };
      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted pink',
          color: state.isSelected ? 'white' : 'black',
          padding: 20,
          display:'flex',
          flexDirection:'row'
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          width: '98%',
          paddingRight:'0px',
          backgroundColor: '#e1e4ea',
          /* border-color: #e1e4ea;
          border-style: solid; */
          border:'none',
          height: '25px',
          paddingBottom:'20px',
          float:'right',
          outline: 'none'
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
      }
      
      return edit ? (
        <div className="main">
        <div className="login-P">
        <Formik 
           initialValues={{
               name:"",
               number:"",
               about:"",
               industry:industry,
               building:'',
               street:'',
               city:"",
               zip:""
           }}
           validationSchema={Yup.object({
            name: Yup.string()
              .max(150, "Maximum of 150 characters"),
            number: Yup.string()
              .max(15,"Please enter a valid number"),
            // industry: Yup.string()
            //   .max(150, "Please Enter a valid industry"),
            about: Yup.string()
              .max(2000,"Please enter a shorter description"),
            building : Yup.string().max(150,"Please enter less than 150 characters"),
            street : Yup.string()
              .max(200,"Please enter less than 200 characters"),
            city : Yup.string().max(200,"Please enter less than 200 characters"),
            zip: Yup.string().max(30,"Please enter a zip code lesser than 30 characters")  
    
            })}

            onSubmit={(values)=>{
              setLoading(true)
              const info = values
              const data = {
                name:info.name ,
                number: info.number ,
                about: info.about ,
                industry: industry ,
                building: info.building,
                street: info.street ,
                city: info.city ,
                zip: info.zip
              }
              profileInfoSignup(data)
            }}
        >

          <Form>

          <div className="row">
             <h4>Basic Information</h4>
         </div>
         <div className="row">

         <MyTextInput
         label="Name"
         name="name"
         type="text"
         placeholder='Enter Company Name'
         />
         </div>

         <div className="row">

         <MySelect
          options={industries}
          name="industry"
          label="Select Industry"
         />

         </div>

         <div className="row">
         <MyTextInput
         label="Number"
         name="number"
         type="text"
         placeholder='Enter Number' 
         />
         </div>

         <div className="row">
             <h4>Address</h4>
         </div>
         <div className="row">
         <MyTextInput
         label="Building"
         name="building"
         type="text"
         placeholder='Enter Building'
         />
         </div>

         <div className="row">
         <MyTextInput
         label="Street"
         name="street"
         type="text"
         placeholder='Enter Street' 
         />
         </div>
         <div className="row">
         <MyTextInput
         label="City"
         name="city"
         type="text"
         placeholder= 'Enter City' 
         />
         </div>

         <div className="row">
         <MyTextInput
         label="Zip"
         name="zip"
         type="text"
         placeholder='Enter ZIP' 
         />
         </div>
         <div className="row">
             <h4>About</h4>
         </div>
         <div className="row">

           <MyTextArea 
           name="about"
           type="text"
           placeholder='  About'
           />

         </div>
         <button className="button" type="submit">SUBMIT</button>
          </Form>

        </Formik>
        <div className="row">
          <BeatLoader
          size={12}
          color={"#7B8B8C"}
          loading = {loading}
          />
          </div>
        </div>
    </div>
      ) : (
      <div className="main">
        <div className="uploadPic">
          <div className="row">
            <img src={image?image:Photo} className="logo"/>
          </div>
          <div className="row">
           <div className="row">
               <input type="file" name="file" id="file" onChange={picChange} placeholder="Choose Image" accept="image/*"/>
               <div className="label">  
               <label htmlFor="file" className="file-upload">
                 <img src={Add} className="jobIcon"/>
                 COMPANY LOGO
               </label>
               </div>
           </div>
        </div>
        <div className="row">
          <BeatLoader
          size={12}
          color={"#7B8B8C"}
          loading = {loading}
          />
        </div>
        </div>
      </div>
    )
      
  }

  export default withRouter(ProfileInfoCp)