import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import LogoMain from '../../assets/images/logomain.png'


const Div = styled.div`
  list-style: none;
  display: flex;
  flex-flow: row-reverse nowrap;
  li {
    padding: 10px 20px;
    list-style-type: none; 
  }
  li a{
    color: rgb(37, 37, 41)!important;
    text-decoration:none;
    font-weight:500;
  }
  @media (max-width: 768px) {
    z-index:999;  
    flex-flow: column nowrap;
    background-color: #ffff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const RightNavLanding = ({open}) =>{
    return(
        <Div open={open}>
          <ul >
          <div >
              <Link to='/'>
              <img src={LogoMain} className="logo-landing"/>
              </Link>
          </div>
                  <li>
                    <Link to='/about'>
                    About Us
                    </Link>  
                  </li>
                  <li>
                    <Link to='/contact'>
                    Contact
                    </Link>  
                  </li>
          </ul>
        </Div>
    )
}

export default RightNavLanding