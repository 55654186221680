
const BASE_URL = 'https://www.efrontliners.com'
//const BASE_URL = 'http://localhost:8080'


export const routesCp = {
    login:`${BASE_URL}/cp/login`,
    signup:`${BASE_URL}/cp/signup`,
    profile:`${BASE_URL}/cp/profile`,
    logo:`${BASE_URL}/cp/img/profile`,
    jobs:`${BASE_URL}/cp/jobs`,
    recruiters:`${BASE_URL}/cp/recruiters`,
    addRecruiter:`${BASE_URL}/cp/add`,
    recruiterImage:`${BASE_URL}/cp/img/recruiters`,
    addLogo:`${BASE_URL}/cp/img/profile`
}


export const routesRc = {
    login:`${BASE_URL}/rc/login`,
    profile:`${BASE_URL}/rc/profile`,
    jobs:`${BASE_URL}/rc/jobs`,
    editJob:`${BASE_URL}/rc/jobs/edit`,
    removeJob:`${BASE_URL}/rc/jobs/remove`,
    addJob:`${BASE_URL}/rc/jobs/add`,
    applications:`${BASE_URL}/rc/jobs/applications`,
    getProfile:`${BASE_URL}/rc/view`,
    getDocuments:`${BASE_URL}/rc/view/docs`,
    archiveJob:`${BASE_URL}/rc/jobs`
}
