import React,{useState,useEffect, useRef, useCallback} from 'react'
import '../../styles/jobView.css'
import '../../styles/responsive.css'
import Next from '../../assets/images/next.png'
import Close from '../../assets/images/close.png'
import Right from '../../assets/images/right-arrow.png'
import Profession from '../../assets/images/profession.png'
import Archive from '../../assets/images/archive.png'
import Location from '../../assets/images/location.png'
import { Redirect,Link,withRouter } from "react-router-dom";
import {useAuth} from '../../service/authContext'
import {RecruiterStore} from '../../service/recruiterStore'
import Modal from 'react-modal'
import Skeleton from 'react-loading-skeleton'
import { BeatLoader } from 'react-spinners';



function JobsView(props){
    const[showDetails,setDetails] = useState()
    const[modalIsOpen,setModalIsOpen] = useState(false)
    const[responsive,setResponsive] = useState(false)

    const {loginStateRc} = useAuth()
    const {fetchJobs,jobs,loadingJobs,archiveJob,loadMore,end} = RecruiterStore()

    const page = useRef(1)
    const observer = useRef()
    
    const lastJob = useCallback(node =>{

      if(observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting){
          if(end === false){

            page.current = page.current + 1
            fetchJobs(page.current,"active")
          }else{
            return null
          }
        }
      })

      if(node) observer.current.observe(node)
    },[])



    useEffect(()=>{
      if(window.innerWidth <= 600) setResponsive(true)
    },[])
    
    useEffect(()=>{
      if(jobs && jobs.length === 0){
        fetchJobs(page.current,"active")
      }
    },[])



    const confirm = (id)=>{
      let y = window.confirm('Are you sure you want to Archive this Job? \nIt will no longer be available live\nThis action cannot be undone')
      if(y === true){
        archiveJob("archive",id)
      }
    }
    
    const jobDescription = (item)=>{
      return <div className="ll">
      <div className="jobRow">
      {responsive?      <button onClick={()=>setModalIsOpen(false)}>
        <img src={Close} className="jobIcon"/>  
      </button> : null}  
 

      
      </div>  

      <div className="jobContainer">
      <div className="jobRow">
          <h4 className="jobTitle">{item.title}</h4>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Listed :</h4>
          <p className="certPara">{item.created}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Profession :</h4>
          <p className="certPara">{item.profession}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Industry :</h4>
          <p className="certPara">{item.industry}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Salary :</h4>
          <p className="certPara">{item.pay}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">Location :</h4>
          <p className="certPara">{item.location}</p>
        </div>
        <div className="jobRow">
          <h4 className="certHeading">About :</h4>
        </div>
        <div className="jobRow">
          <p className="certPara">{item.about}</p>
        </div>
        <div className="btn-row">
          <Link
            to={{
              //Path to applications page
              pathname: '/recruiter/jobs/applications',
              //Job data is passed so it can be displayed on the application's page
              state: {
                Job: item,
              },
            }}>
          <button className="application">View Applications<img src={Right} className="jobIcon"/></button>
          </Link>
        </div>
        <div className=" btn-row">
          <button className="archive-btn" onClick={()=>confirm(item.id)}>
            Archive Job
            <img src={Archive} className="jobIcon"/>  
          </button>
        </div>

     </div>
     <div>
     </div>
     </div>
     
    }


    const showJobDetails = (item) =>{
      setDetails(item)
      if(responsive) setModalIsOpen(true)
 
    }

    const showJobs = (value)=>{
        const data = value
        if(value !== undefined){
        return data.map((item,index)=>{

          if(data.length === index +1){

            return <a className="listContainer" ref={lastJob} key={index} onClick={()=>showJobDetails(item) }>
                <div className="listHeading">
                    <div className="listSelect listRow-J">
                      <h4>{item.title.substring(0,40)}..</h4>
                      <img src={Next} className="jobIcon"/>
                    </div>
                    <div className="listRow-J">
                      <div className="row">
                        <img src={Profession} className="jobIcon"/>
                        <p>{item.profession}</p> 
                      </div>
                      <div className="row">
                        <img src={Location} className="jobIcon"/>
                        <p>{item.location}</p> 
                      </div>
                    </div>
                </div>
            </a>
          }else{
            return <a className="listContainer" key={index} onClick={()=>showJobDetails(item) }>
            <div className="listHeading">
                <div className="listSelect listRow-J">
                  <h4>{item.title.substring(0,40)}..</h4>
                  <img src={Next} className="jobIcon"/>
                </div>
                <div className="listRow-J">
                  <div className="row">
                    <img src={Profession} className="jobIcon"/>
                    <p>{item.profession}</p> 
                  </div>
                  <div className="row">
                        <img src={Location} className="jobIcon"/>
                        <p>{item.location}</p> 
                      </div>
                </div>
            </div>
            </a>

          }
        })
      }
    }

    return loginStateRc?(
        <div>
           <div className="main">

             <div className="mainContainer">

             <div className="container">

                {(!loadingJobs)?showJobs(jobs):
                   <div className="listContainer">
                      <div className="listHeading">
                          <div className="skeleton">
                          <Skeleton height={25} width={`90%`}/> 
                          </div>
                          <div className="skeleton">
                          <Skeleton height={15} width={`25%`}/> 
                          </div>
                      </div>
                  </div>
                }

                <div style={{margin:'20px'}} className="row">
                  <BeatLoader
                     size={13}
                     color={"#7B8B8C"}
                     loading = {loadMore}
                   />

                   {(end)?(<div> <h5 className="row">END</h5></div>) : null}
                </div>


             </div>

             <div className="jobDetails">

                {(showDetails)?jobDescription(showDetails):
                     <div className="job-skeleton">
                     <div>     
                     <Skeleton width={`90%`} height={40} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`30%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`45%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`40%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`50%`} height={26} duration={0}/>
                     </div>
                     <div>     
                     <Skeleton width={`35%`} height={26} duration={0}/>
                     </div>
                     <div >     
                     <Skeleton width={`16%`} height={26} duration={0}/>
                     </div>
                     <div className="abt-skeleton">     
                     <Skeleton width={`90%`} height={20} count={2} duration={0}/>
                     <Skeleton width={`35%`} height={20} duration={0}/>
                     <Skeleton width={`90%`} height={20} count={2} duration={0}/>
                     <Skeleton width={`70%`} height={20} duration={0}/>
                     </div>
                   </div>
                }


             </div>
             <Modal isOpen={modalIsOpen}>
                {(showDetails !== undefined)?jobDescription(showDetails):null}
                {/* {jobDescription(showDetails)} */}
              </Modal>
             </div>

           </div>
        </div>

    ):<Redirect to='/recruiter/login'/>
}

export default withRouter(JobsView)
