import React,{useState,useEffect} from 'react'
import '../../styles/jobAdd.css'
import {withRouter,Redirect} from 'react-router-dom'
import {useAuth} from '../../service/authContext'
import {RecruiterStore} from '../../service/recruiterStore'
import { Formik,Form,useField } from 'formik'
import * as yup from 'yup'
import {industries,professions} from '../../assets/enums/industries'
import Select from 'react-select';
import { BeatLoader } from 'react-spinners';




const MyTextInput = ({label,...props})=>{
    const[field,meta] = useField(props);

    return(
      <>
     <div className="row"> 
      <label htmlFor={props.id||props.name}>{label}</label>
      <input className="text-input input-P" {...field} {...props} />
     </div> 
    
     <div className="row-error"> 
      { meta.error ? (
       <p className="error">*{meta.error}</p>
      ):null}
     </div> 
      </>
    );
  };
  
  const MyTextArea = ({label,...props})=>{
    const[field,meta] = useField(props);

    return(
      <>
     <div className="row"> 
      <textarea className="jobAbt" {...field} {...props}>
      
      </textarea>
     </div> 
    
     <div className="row-error"> 
      {meta.error ? (
       <p className="error">*{meta.error}</p>
      ):null}
     </div> 
      </>
    );
  };

  const validationSchema = yup.object({
    title:yup.string()
    .min(3,"Minimum of 2 Characters allowed")
    .max(250,"Maximum of 250 characters allowed").required("Job Title is required"),
    // industry:yup.string().min(2,"Minimum of 2 characters allowed")
    // .max(250,"Maximum of 250 characters allowed").required("Industry is required"),
    // profession:yup.string().min(2,"Minimum of 2 characters allowed")
    // .max(250,"Maximum of 250 characters allowed").required("Job Profession is required"),
    pay:yup.string().max(15,"Please enter a valid Pay"),
    location:yup.string().min(2,"Minimum of 2 Characters required")
    .max(250,"Maximum 250 characters").required("Job Location is required"),
    about:yup.string().max(4500,"Maximum of 4500 characters allowed, Please enter a shorter description").required("Job Description is required"),
  })

function JobAdd(props){
  const[rec,setRec] = useState('')
  const[cp,setCp] = useState('')
  const{loginStateRc,recruiter} = useAuth()
  const{addJob} = RecruiterStore()
  const[industry,setIndustry] = useState()
  const[profession,setProfession] = useState()
  const[loading,setLoading] = useState(false)

  useEffect(()=>{
    getData()
  },[])
  
  const getData = ()=>{
    setRec(localStorage.getItem('rc'))
    setCp(recruiter.Company)
  }

  const SelectIndustry = (props) => {

    return (
        <>
        <label htmlFor={props.id || props.name}>{props.label}</label>
       <Select
         styles={customStyles}
         {...props}
         className="input"
         value={industries.filter(function(option) {
          return option.value === industry;
        })}
         onChange={value => handleChangeIndustry(value)}
         options={props.options}
        //  placeholder={(company.industry === '')? 'Enter Industry' : company.industry}
       />
    
        </>
    )
  }

  const SelectProfession = (props) => {

    return (
        <>
        <label htmlFor={props.id || props.name}>{props.label}</label>
       <Select
         styles={customStyles}
         {...props}
         className="input"
         value={professions.filter(function(option) {
          return option.value === profession;
        })}
         onChange={value => handleChangeProfession(value)}
         options={props.options}
        //  placeholder={(company.industry === '')? 'Enter Industry' : company.industry}
       />
    
        </>
    )
  }

  const handleChangeIndustry = selectedOption => {
    if(selectedOption)
    setIndustry(selectedOption.value);
  };
  const handleChangeProfession = selectedOption => {
    if(selectedOption)
    setProfession(selectedOption.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'black',
      padding: 20,
      display:'flex',
      flexDirection:'row'
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '98%',
      paddingRight:'0px',
      backgroundColor: '#e1e4ea',
      /* border-color: #e1e4ea;
      border-style: solid; */
      border:'none',
      height: '25px',
      paddingBottom:'20px',
      float:'right',
      outline: 'none'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }




  const postJob = (data)=>{
    setLoading(true)
     let userData = {
        title:data.title,
        industry:industry,
        profession:profession,
        pay:data.pay,
        location:data.location,
        about:data.about
     }

     addJob(userData)
  }

  return loginStateRc? (
      <>
      <div className="main job-add">

        <div className="login-P">

        
         <Formik 
         initialValues={{
           title:'',
           industry:industry,
           profession:profession,
           pay:'',
           location:'',
           about:''
         }}
        
         validationSchema={validationSchema}
         onSubmit={(values)=>{
           postJob(values)
           }}
           >
         
         
          

         <Form>
            
           <MyTextInput 
             label="Title"
             name="title"
             type="text"
             placeholder="Enter Job Title"
             onBlur={null}
           />

           {/* <MyTextInput 
             label="Industry"
             name="industry"
             type="text"
             placeholder="Enter Industry"
           /> */}
           <div className="row">
           <SelectIndustry
            options={industries}
            name="industry"
            label="Select Industry"
           />
           </div>

           {/* <MyTextInput 
             label="Profession"
             name="profession"
             type="text"
             placeholder="Enter Job Profession"
           /> */}
          <div className="row">
          <SelectProfession
            options={professions}
            name="profession"
            label="Select Profession"
           />
          </div> 

           <MyTextInput 
             label="Salary"
             name="pay"
             type="text"
             placeholder="Enter Salary Offered"
           />

           <MyTextInput 
             label="Location"
             name="location"
             type="text"
             placeholder="Enter Job Location"
           />
          
          <div className="row"> 
            <label htmlFor='about'>Job Description</label>
          </div>
     
           <MyTextArea 
           name="about"
           type="text"
           placeholder="Enter Job Description"
           />
           <button type="submit" className="button">ADD JOB</button>
         </Form>
          
         </Formik>
         <div className="row">
         <span>
          <BeatLoader
          size={12}
          color={"#7B8B8C"}
          loading = {loading}
        />
        </span>
         </div>
         </div>
      </div>
      </>
  ) : (<Redirect to="/recruiter/login" />)

}

export default withRouter(JobAdd)