import React,{createContext,useState} from 'react'
import history from '../components/history'
import axios from 'axios'
import {routesRc} from '../api/routes'
import {useAuth} from './authContext'

const RecruiterData = createContext()

const RecruiterProvider = (props)=>{

    //Recruiter Profile
    const[recruiter,setRecruiter] = useState()
    const[imgUrl,setUrl] = useState()
    const[applicant,setApplicant]= useState()
    const[docs,setDocs] = useState([])
    const[loadingJobs,setLoadingJobs] = useState(true)
    const[loadMore,setLoadMore] = useState(false)




    //Recruiter Jobs
    const[jobs,setJobs] = useState([])
    const[archives,setArchives] = useState([])
    const[end,setEnd] = useState(false)
    const[endList,setEndList] = useState(false)


    //Fetch jobs posted by the Recruiter
    const fetchJobs = async (page)=>{
        try{
            if(!end){
            setLoadMore(true)
            let recruiterId = await localStorage.getItem('rc')
            let token = await localStorage.getItem('tkRc')
            let route = routesRc.jobs

            let req = await axios.get(`${route}/${recruiterId}?page=${page}&status=${"active"}`,{
                headers:{
                    "Content-Type":'application/json',
                    'authorization':`Bearer ${token}` 
                }
            }).then(res=>{
                if(res.status === 200 || res.status === 'OK'){
                    
                    if(res.data.length === 0){
                        setEnd(true)
                    } 

                    setTimeout(()=>{
                        setLoadingJobs(false)
                    },2000)
                    setLoadMore(false)
                    setJobs(jobs=>[...jobs,...res.data])

                }
            }).catch(e=>{
                alert("Something has went wrong, Please try after some time")  
                // return history.push('/company/home')
            })
          }else{
          }
        }catch(e){
            alert("Something has went wrong, Please try after some time")  
            // return history.push('/recruiter/')
        }
    }

    const fetchArchives = async (page)=>{
        try{
            if(!endList){
            setLoadMore(true)
            let recruiterId = await localStorage.getItem('rc')
            let token = await localStorage.getItem('tkRc')
            let route = routesRc.jobs

            let req = await axios.get(`${route}/${recruiterId}?page=${page}&status=${"archived"}`,{
                headers:{
                    "Content-Type":'application/json',
                    'authorization':`Bearer ${token}` 
                }
            }).then(res=>{
                if(res.status === 200 || res.status === 'OK'){
                    
                    if(res.data.length === 0){
                        setEndList(true)
                    } 

                    setTimeout(()=>{
                        setLoadingJobs(false)
                    },2000)
                    setLoadMore(false)
                    let archive = new Set([...archives,...res.data])
                    setArchives(Array.from(archive))

                }
            }).catch(e=>{
                alert("Something has went wrong, Please try after some time")  
                // return history.push('/company/home')
            })
          }
        }catch(e){
            alert("Something has went wrong, Please try after some time")  
            // return history.push('/recruiter/')
        }
    }

    
    const archiveJob = async (status,jobId)=>{
        let token = await localStorage.getItem('tkRc')
        let route = routesRc.archiveJob
        
        let params = {
            method:'PATCH',
            headers:{
                "Content-Type":'application/json',
                'authorization':`Bearer ${token}` 
            }
        }

        const req = await fetch(`${route}/${status}/${jobId}`,params)
          .then(async Response=>{
              if(Response.ok){
                alert("Job has been archived")
                setJobs(jobs.filter((job)=> job.id !== jobId))
              }else{
                alert("Something has went wrong, Please try again later")
            }
          }).then(Data=>{
        })

    }
    //Get Applicant's Profile
    const getProfile = async(id)=>{

        try{
          let route = routesRc.getProfile
            let token = await localStorage.getItem('tkRc')
  
          let req =await axios.get(`${route}/${id}`,{
            headers:{
                "Content-Type":'application/json',
                'Authorization':`Bearer ${token}` 
            }
        }).then(res=>{
              setApplicant(res.data)
          })
          // let two = axios.get(`/rc/view/docs/${id}`)
          
          // const req = await axios.all([one,two]).then(axios.spread((...responses)=>{
          //     let resOne = responses[0]
          //     let resTwo = responses[1]
  
          //     setApplicant(resOne.data)
          //     setDocs(resTwo.data)
          //     console.log(resTwo.data)
          // })).catch(e=>console.log(e))
          
  
        }catch(e){
  
        }
    
      }
      
    //Get Applicant's Documents
      const getDocs =async (id)=>{

        let token = await localStorage.getItem('tkRc')
        let route = routesRc.getDocuments

        let req =  axios.get(`${route}/${id}`,{
            headers:{
                "Content-Type":'application/json',
                'Authorization':`Bearer ${token}` 
            }
        }).then(res=>{
            console.log(res.data)

            if(res.data === "No Documents"){
                setDocs("No Docs")
            }

            setDocs(res.data)
        })
    }
    

    //Add Job Listing
    const addJob = async(data)=>{
        try{
            let recruiterId = await localStorage.getItem('rc')
            let token = await localStorage.getItem('tkRc')
            let company = await localStorage.getItem('cpRc')

            let route = routesRc.addJob

          let jobData = data
         
          const req = await axios.post(`${route}/${recruiterId}/${company}`,jobData,{
            headers:{
                "Content-Type":'application/json',
                'Authorization':`Bearer ${token}` 
            }
        }).then(res=>{
              if(res.status === 200){
                alert("Job successfully added")
                return history.push('/recruiter/')
              }
          }).catch(e=>{
              alert("Something has went wrong, Please try again")
          })
        }catch(e){
            alert("Something has went wrong, Please try again")
        }
    }
    

    const values = {
        jobs,
        fetchJobs,
        recruiter,
        imgUrl,
        getProfile,
        getDocs,
        applicant,
        docs,
        addJob,
        loadingJobs,
        archiveJob,
        end,
        loadMore,
        fetchArchives,
        archives,
        endList
    }
    return <RecruiterData.Provider value={values} {...props} />;
}

const RecruiterStore = () => React.useContext(RecruiterData)
export {RecruiterProvider,RecruiterStore}