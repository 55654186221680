import React,{useState,useEffect,useRef, useCallback} from 'react'
import '../../styles/applications.css'
import '../../styles/responsive.css'
import Nationality from '../../assets/images/nationality.png'
import Phone from '../../assets/images/phone.png'
import axios from 'axios'
import {Redirect, withRouter,Link} from 'react-router-dom'
import {useAuth} from '../../service/authContext'
import {routesRc} from '../../api/routes' 
import Skeleton from 'react-loading-skeleton'




function ViewApplications(props){
    const[job,setJob] = useState()
    const[users,setUsers] = useState([])
    const[exist,setExist] = useState(false)
    const[responsive,setResponsive] = useState(false)
    const[loadMore,setLoadMore] = useState(false)

    
    const {loginStateRc} = useAuth()


    const page = useRef(1)
    const observer = useRef()
    const end = useRef(false)

    const lastOnScreen = useCallback(node=>{
      if(observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries =>{
        if(entries[0].isIntersecting){
          if(end.current === false){
          page.current = page.current + 1
          getApplicants(page.current)
          }
        }else{
          return null
        }
      })
      if(node) observer.current.observe(node)

    },[])

    useEffect(()=>{
      if(window.innerWidth <= 600) setResponsive(true)
      if(loginStateRc){
        //The job id is being fetched from the jobview page on button click
        getApplicants(page.current)
        setJob(props.location.state.Job)
      } 
    },[])

    const getApplicants = async (page)=>{
        //Need to use the id from localstorage actually

        if(end.current === false){
        console.log("Sending req",page)
        let token = await localStorage.getItem('tkRc')
        let route = routesRc.applications
          let req = await axios.get(`${route}/${props.location.state.Job.id}?page=${page}`,{
            headers:{
                "Content-Type":'applicantion/json',
                'authorization':`Bearer ${token}`
            }
        }).then(Response=>{
            console.log(Response.data)
            if(Response.status === 204){
              end.current = true
            }
            setUsers(users=>[...users,...Response.data])
        })
      }else if(end){
        console.log("NOT SENDING REQ")
        return null
      }

    }
    

    const showApplicants = (data)=>{
        let value = data
        console.log(data)
        if(value!== undefined){
        return value.map((item,index)=>{

          if(value.length === index + 1){
            return <Link
            key={index}
            to={{
              pathname:'/recruiter/jobs/applications/profile',
              state:{
                Applicant : item
              }
            }}>
           <div 
            ref={lastOnScreen}
           className="profileTab">
                <div className="listHeading">
                    <div className="listRow">
                      <div className="picContainer">
                        <img src={`${item.photo}`} id="dp"/>
                      </div>

                      <div className="listRow">
                        <div className="containerRows">
                        <div className="listRow">
                        <div className="row">
                        <h3>{item.name}</h3>                        
                        </div>  
                        </div>
                        <div className="listRow">
                          <div className="row">
                            <img src={Nationality}/>
                            <p>{item.nationality}</p>
                          </div>
                          <div className="row">
                            <img src={Phone}/>
                            <p>{item.number}</p>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            </Link>
          }else{
            return <Link
            key={index}
            to={{
              pathname:'/recruiter/jobs/applications/profile',
              state:{
                Applicant : item
              }
            }}>
           <div className="profileTab">
                <div className="listHeading">
                    <div className="listRow">
                      <div className="picContainer">
                        <img src={`${item.photo}`} id="dp"/>
                      </div>

                      <div className="listRow">
                        <div className="containerRows">
                        <div className="listRow">
                        <div className="row">
                        <h3>{item.name}</h3>                        
                        </div>  
                        </div>
                        <div className="listRow">
                          <div className="row">
                            <img src={Nationality}/>
                            <p>{item.nationality}</p>
                          </div>
                          <div className="row">
                            <img src={Phone}/>
                            <p>{item.number}</p>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            </Link>
          }
        })
      }
    }

    const showJob = (job)=>{
    return !responsive? <div className="jobAbout">
      <div className="listRow">
       <h4>{job.title}</h4>
      </div>
      <div className="listRow">
       <h5>Industry :</h5> 
       <p> {job.industry}</p>
      </div>
      <div className="listRow">
      <h5>Profession :</h5> 
       <p> {job.profession}</p>
      </div>
      <div className="listRow">
      <h5>Salary :</h5> 
       <p>{job.pay}</p>
      </div>
      <div className="listRow">
      <h5>Location :</h5> 
       <p>{job.location}</p>
      </div>
      <div className="listRow">
      <h5>About :</h5> 
      </div>
      <div className="listRow">
      <p>{job.about}</p>
      </div>

    </div>
    : <div className="jobAbout">
        <div className="listRow">
          <h4>{job.title}</h4>
        </div>
        <div className="listRow">
          <div className="listRow"> 
          <h5>Location :</h5> 
         <p>{job.location}</p>
          </div>
        </div>
        <div className="listRow">
      <h5>Profession :</h5> 
       <p> {job.profession}</p>
      </div> 

    </div>
    }

    return loginStateRc? (
      props.location.state.Job? <div>

           <div className="main">
             <div className="mainContainer">
             <div className="aboutJob">
              {(job)?showJob(job):null}
             </div>

              <div className="scrollContainer">
                <div className="profileList">
               

                  {showApplicants(users)}
              

                </div>
                {(end && users.length !== 0)?
                <div className="row" style={{margin:20,paddingBottom:50}}>
                   <h3>End of the List</h3>
                </div> 
                :
                <div className="row"  style={{margin:20,paddingBottom:50}}>
                  <h3 style={{fontSize:15}}>There are no Applications found</h3>
                </div>
                }

              </div>

             </div>
           </div>
        </div> : <Redirect to='/recruiter/jobs'/>

    ):(<Redirect to="/recruiter/login"/>)
}

export default withRouter(ViewApplications)

