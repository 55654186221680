import React from 'react';
import {withRouter} from 'react-router-dom';
import abt from '../../assets/images/hd3.jpg';
import '../../styles/animated-bg.css'


const About = ()=>{

    const width = window.innerWidth
    return(
        <div className="landing-main">
        { width <= 600 ? 
                <div className="about  test">

                <div className="landing-about">
                    <h1>ABOUT US</h1>
                    <br></br>
                    <h4>With over 20 years of experience in manpower industry in UAE, we are
                   starting this venture to provide a platform to the frontline and blue
                   collar workers to present their credentials and find suitable jobs.
                   </h4>
                   <br></br>
                   <h4>
                   In the region the frontline staff has to come to GCC using the agents
                  by paying a lot of amount and work hard to recover these funds. Also
                  very few grow in their jobs as they do not have opportunity inspite of
                  working hard. This platform will provide them with the platform and
                  grow.
                   </h4>
                  <br></br>
                  <h4>
                      Once they get terminated the staff return back to their countries and
                  return back by using agents.
                  </h4>
                  <br></br>
                  <h4>
                      Our aim is to help them grow and provide them with best possible
                  jobs, help them in need and grow in their jobs.
                  </h4>
                </div>
            </div>

            :

            <div className="about " style={{backgroundImage:`url(${abt})`}}>

            <div className="landing-about">
                <h1>ABOUT US</h1>
                <br></br>
                <h4>With over 20 years of experience in manpower industry in UAE, we are
               starting this venture to provide a platform to the frontline and blue
               collar workers to present their credentials and find suitable jobs.
               </h4>
               <br></br>
               <h4>
               In the region the frontline staff has to come to GCC using the agents
              by paying a lot of amount and work hard to recover these funds. Also
              very few grow in their jobs as they do not have opportunity inspite of
              working hard. This platform will provide them with the platform and
              grow.
               </h4>
              <br></br>
              <h4>
                  Once they get terminated the staff return back to their countries and
              return back by using agents.
              </h4>
              <br></br>
              <h4>
                  Our aim is to help them grow and provide them with best possible
              jobs, help them in need and grow in their jobs.
              </h4>
            </div>
        </div>
        }
        

        </div>
    )
}

export default withRouter(About)