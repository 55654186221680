import React, { useState } from 'react'

import {withRouter,useParams} from 'react-router-dom'
import {Formik,Form,useField} from 'formik'
import * as yup from 'yup'
import { BeatLoader } from 'react-spinners'
import axios from 'axios'
import history from '../../components/history'

const MyTextInput = ({label,...props})=>{
    const[field,meta] = useField(props);
    return(
      <>
     <div className="row"> 
      <input className="input" {...field} {...props} />
     </div> 
    
     <div className="row-error"> 
      {meta.touched && meta.error ? (
       <p className="error">*{meta.error}</p>
      ):null}
     </div> 
      </>
    );
}; 

const validationSchema = yup.object({
    password:yup.string().min(6,"'password should be a minimum of 6 characters").required("Password is required"),
    confirm:yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match')
})

const ResetPasswordAp = ()=>{
    const[loading,setLoading] = useState(false)
    const {user,id,token} = useParams()

    const submit =async (values)=>{
         //send reset password api call

         let route
         if(user === "company"){
           route = 'cp'
         }else if(user === 'recruiter'){
           route = 'rc'
         }else if(user === 'applicant'){
           route = 'ap'
         }
         let req = await axios.post(`https://www.efrontliners.com/${route}/reset/${id}/${token}`,{
          "password":`${values.password}`
        })
          .then(Response=>{
            if(Response.status === 200){
              alert("Password has been Reset")
              if(route === 'cp' || route === 'rc'){
                return history.push('/')
              }
            }
          })
    }

    return(
        <div className="forgot">
            <div className="forgot-tab">
                <div className="row">
                    <h3>Reset Password</h3>
                </div>
               <Formik
                initialValues={{
                    password:'',
                    confirm:''
                }}
                validationSchema={validationSchema}
                onSubmit={(values)=>{
                    setLoading(true)
                    submit(values)
                }}
                >
                    <Form>
                     <MyTextInput 
                       name="password"
                       type="password"
                       placeholder="Enter your password"
                     />

                    <MyTextInput 
                       name="confirm"
                       type="password"
                       placeholder="Confirm your password"
                     />
                    <div className="row">
                      <button type="submit" className="button">RESET PASSWORD</button>
                    </div>
                    </Form>
               </Formik>
               <div className="row">
               <BeatLoader
                 size={8}
                 color={"#7B8B8C"}
                 loading = {loading}
               />
               </div>

            </div>
        </div>
    )
}

export default withRouter(ResetPasswordAp)