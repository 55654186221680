import React, { useEffect, useState } from 'react'
import {useParams, withRouter} from 'react-router-dom'
import '../../styles/forgot.css'
import Company from '../../assets/images/company.png'
import Recruiter from '../../assets/images/recruiter.png'
import axios from 'axios'


function ForgotUsers(props){

  const[email,setEmail] = useState('')
  const[account,setAccount] = useState('')

  const handleChange = (e)=>{
     setEmail(e.target.value)
  }
  
  useEffect(()=>{

    if(user === "company"){
        setAccount("Company")
    }else if(user === "recruiter"){
        setAccount("Recruiter")
    }

  },[])

  const {user} = useParams()
  const handleSubmit = async()=>{

    let route = account === "Company"? "cp" : "rc"
    const req = await axios.post(`https://www.efrontliners.com/${route}/forgot`,{
      "email":email
    }).then(Response=>{
      if(Response.status === 200){
        alert("An email has been sent to reset your password")
      }
    })

  }
    return(
        <div className="forgot">
           <div className="forgot-tab">
               <div className="row">
                  <img src={account === "Company"?Company:Recruiter} className="jobIcon-R"/>
                  <h3 className="forgot-header">{account} Account</h3>
               </div>
              <div className="row">
                <h3>Email address</h3>
              </div>
              <div className="row">
                 <input 
                  className="input input-forgot"
                  placeholder="Please enter your Email address"
                  onChange={handleChange}
                 />
              </div>
              <div className="row">
                <button className="button" type="submit" onClick={handleSubmit}>
                  SUBMIT
                </button>

              </div>
           </div>
        </div>
    )
}

export default withRouter(ForgotUsers)