import React,{useState,useEffect} from 'react'
import '../../styles/viewProfile.css'
import {Redirect, withRouter} from 'react-router-dom'
import {useAuth} from '../../service/authContext'
import {RecruiterStore} from '../../service/recruiterStore'


import Img from '../../assets/images/image.png'
import Close from '../../assets/images/cancel-white.png'

import Profession from '../../assets/images/profession.png'
import Email from '../../assets/images/email.png'
import Phone from '../../assets/images/phone.png'
import Nationality from '../../assets/images/nationality.png'
import Location from '../../assets/images/location.png'
import Gender from '../../assets/images/gender.png'
import Skeleton from 'react-loading-skeleton';
import Modal from 'react-modal';

import prev from '../../assets/images/prev-page.png';
import next from '../../assets/images/next-page.png';
import jobs from '../../assets/images/jobs.png'






import { pdfjs } from 'react-pdf';
import { Page, Document} from 'react-pdf/dist/esm/entry.webpack';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;




function ViewProfile(props){


    const[showDetails,setDetails] = useState()

    const {loginStateRc} = useAuth()
    const{getProfile,getDocs,applicant,docs} = RecruiterStore();

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [renderNavButtons, setRenderNavButtons] = useState(false);

    const [ modalOpen,setOpen ] = useState(false);

    const onSuccess = (sample) => {
      // alert('PDF document loaded successfully!');
      setPageNumber(1);
      setRenderNavButtons(true);
    }

    const changePage = (offset) => {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => { changePage(-1); }
    const nextPage = () => { changePage(+1); }

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    useEffect(()=>{
      if(loginStateRc){
       getProfile(props.location.state.Applicant.id)
       getDocs(props.location.state.Applicant.id)
      }
    },[])


    const showDocs = (data)=>{

        if(data === "No Docs"  || data == undefined) return null
        function showCerts(data){
          if(data !== "No Certificates Added"){
            
            return data.map((item)=>{
            return <div className="listRow-R">
              <div className="rowApp">
                  <img src={`${item.photo}`} />
              </div>
              <div className="rowApp">
                  <h4>Certificate type : </h4>
                 <h3>Education/Training</h3>
              </div>
              <div className="rowApp">
                  <h4>Course : </h4>
                 <h3>{item.course}</h3>
              </div>

              <div className="rowApp">
              <h4>Completed : </h4>
                 <h3>{item.complete}</h3>
              </div>
              <div className="rowApp">
              <h4>Institute : </h4>
                 <h3>{item.institute}</h3>
              </div>
              <div className="rowApp">
              <h4>Location : </h4>
                 <h3>{item.location}</h3>
              </div>

            </div>
        })
        }else{
          return <h3>The User has not added any Certificate</h3>
        }
       }

       function showExp(data){
        if(data !== "No Experience Certificates Added"){
        return data.map((item)=>{
            return <div className="listRow-R">
              <div className="rowApp">
                  <img src={`${item.photo}`} />
              </div>
              <div className="rowApp">
                  <h4>Document type : </h4>
                 <h3>Work Experience</h3>
              </div>
              <div className="rowApp">
                  <h4>Company : </h4>
                 <h3>{item.company}</h3>
              </div>
              <div className="rowApp">
              <h4>Designation : </h4>
                 <h3>{item.designation}</h3>
              </div>
              <div className="rowApp">
              <h4>Location : </h4>
                 <h3>{item.location}</h3>
              </div>

            </div>
         })
        }else{
          return <h3>The User has not Added any Experience Certificate</h3>
        }
        }

        return <div style={{position:"relative"}}>
                        <div className="row">
                <h4>Passport</h4>
              </div>
              <div className="row">
                 <h5>Passport Number : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.ppt.number:""}</h5>
              </div>
              <div className="row">
                 <h5>Passport Expiry : </h5>
                 <h5 className="text-muted">{docs.Documents  && docs.Documents.ppt.expiry !== undefined? docs.Documents.ppt.expiry.substring(0,10):''}</h5>
              </div>
              <div className="row">
                 <h5>Passport Issuing Country : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.ppt.country : ""}</h5>
              </div>
              <br></br> 
              <div className="row">
              <h4>Visa</h4>
              </div>
             
              <div className="row">
                 <h5>Visa Number : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.visa.number : ""}</h5>
              </div>
              <div className="row">
                 <h5>Visa Expiry : </h5>
                 <h5 className="text-muted">{docs.Documents && docs.Documents.visa.expiry !== undefined? docs.Documents.visa.expiry.substring(0,10) : ""}</h5>
              </div>
              <div className="row">
                 <h5>Visa Issuing Country : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.visa.country : ""}</h5>
              </div>
             
              <br></br> 
              <div className="row">
                <h4>License</h4>
              </div>
              <div className="row">
                 <h5>License Number : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.license.number : ""}</h5>
              </div>
              <div className="row">
                 <h5>License Expiry : </h5>
                 <h5 className="text-muted">{docs.Documents && docs.Documents.license.expiry !== undefined? docs.Documents.license.expiry.substring(0,10) : ""}</h5>
              </div>
              <div className="row">
                 <h5>License Issuing Country : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.license.country : ""}</h5>
              </div>

              <br></br> 
              <div className="row">
                <h4>Id</h4>
              </div>
              <div className="row">
                 <h5>Id Number : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.id.number : ""}</h5>
              </div>
              <div className="row">
                 <h5>Id Expiry : </h5>
                 <h5 className="text-muted">{docs.Documents && docs.Documents.id.expiry !== undefined? docs.Documents.id.expiry.substring(0,10) : ""}</h5>
              </div>
              <div className="row">
                 <h5>Id Issuing Country : </h5>
                 <h5 className="text-muted">{docs.Documents? docs.Documents.id.country : ""}</h5>
              </div>
              {/* <div className='cert-heading'>
                <h3>Resume</h3>
            </div>
            {showResume(data.Resume)} */}
            <div className='cert-heading'>
                <h3>Certificates</h3>
            </div>
            {showCerts(data.Certificates)}
            <div className='cert-heading'>
                <h3>Experience</h3>
            </div>
            {showExp(data.Experience)}
            
        </div>
    }

    const showCertificates = (item)=>{

      if(item && item === "No Docs" || item === "No Documents"){
        alert("There are no documents uploaded by the User")
      }else{
        setDetails(item)
      }
   
    }

    const showResume = (data)=>{
      console.log("REDUME ",data)
      if(data){
        return(
          <>
          <button className='clode-modal' onClick={()=>{
                  setOpen(value => !value)
          }}>Close <img src={Close} style={{width:'18px',height:'18px',margin:'5px'}}/></button>
                <Document file={`${data}`} 
      // className="pdf-container"
      // renderMode={'svg'}
      renderMode={"svg"}
      
      onLoadSuccess={({ numPages }) => {
        setNumPages(numPages);
        setRenderNavButtons(true);
        onSuccess();
      }} 
      >
        <Page pageNumber={pageNumber}  scale={1}/>
      </Document>
      {renderNavButtons &&
        <>
          <button
          className='button-pdf previous'
          disabled={pageNumber <= 1}
          onClick={previousPage}
          >
          <img  src={prev} className="pdf-button" />
          </button>
        

            <button className='button-pdf next'
            disabled={pageNumber === numPages}
            onClick={nextPage}
            >
          <img  src={next} className="pdf-button " />
            </button>
        </>}

          </>
        )
      }
    }   


    return loginStateRc? (
      applicant? <div className="main view-profile">

        <div className="profileApp">
                <div className="listHeading-R">
                    <div className="listRow-Dp">
                        <img className="profile-Ap" src={(applicant.photo === undefined)? Img : `${applicant.photo}`}/>
                    </div>
                    <div className="listRow-R">
                      <h4>{applicant.name}</h4>
                      {/* <img src={Next} className="jobIcon"/> */}
                    </div>

                    <div className="listRow-R">
                      <div className="row">
                        <img src={Email} className="jobIcon-R"/>
                        <p>{applicant.email}</p> 
                      </div>
                    </div>

                    <div className="listRow-R">
                      <div className="row">
                        <img src={Gender} className="jobIcon-R"/>
                        <p>{applicant.gender}</p> 
                      </div>
                    </div>

                    <div className="listRow-R">
                      <div className="row">
                        <img src={Phone} className="jobIcon-R"/>
                        <p>{applicant.number}</p> 
                      </div>
                    </div>

                    <div className="listRow-R">
                      <div className="row">
                        <img src={Profession} className="jobIcon-R"/>
                        <p>{applicant.profession}</p> 
                      </div>
                    </div>


                    <div className="listRow-R">
                      <div className="row">
                        <img src={Nationality} className="jobIcon-R"/>
                        <p>{applicant.nationality}</p> 
                      </div>
                    </div>
                    <div className="listRow-R">
                      <div className="row">
                        <img src={Location} className="jobIcon-R"/>
                        <p>{`${applicant.address.building},${applicant.address.street?applicant.address.street:null},${applicant.address.city}`}</p> 
                      </div>
                    </div>
                    <div className="row">
                     <button className="button-resume" onClick={()=>setOpen(true)}>
                     <img src={jobs} />View Resume
                      </button>
                  </div>
                  <div className='row'>
                  <button className="button-resume" style={{backgroundColor:"#0b5dbd"}} onClick={()=>showCertificates(docs)}>
                      <img src={jobs} />View Certificates
                      </button>
                  </div>

                  <div className="listRow-R">

                  </div>



                </div>



            </div>
            <div className="appDocs">

              {showDetails?showDocs(showDetails)
              :
              <div className="job-skeleton">
              <div>     
              <Skeleton width={`80%`} height={400} duration={0}/>
              </div>
              <div>     
              <Skeleton width={`30%`} height={26} duration={0}/>
              </div>
              <div>     
              <Skeleton width={`25%`} height={26} duration={0}/>
              </div>
              <div>     
              <Skeleton width={`28%`} height={26} duration={0}/>
              </div>
              <div>     
              <Skeleton width={`40%`} height={26} duration={0}/>
              </div>
            </div>
              }
            </div>
            <Modal isOpen={modalOpen}>
                {showResume(docs.Resume)}
            </Modal>
        </div> : <p>Loading</p>

        

    ):(<Redirect to="/recruiter/login"/>)
}

export default withRouter(ViewProfile)

