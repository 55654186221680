import React, {  useState } from 'react'
import { withRouter} from 'react-router-dom'
import '../../styles/forgot.css'
import axios from 'axios'


function ForgotAp(props){

  const[email,setEmail] = useState('')

  const handleChange = (e)=>{
     setEmail(e.target.value)
  }
  const handleSubmit = async()=>{
    const req = await axios.post('https://www.efrontliners.com/ap/forgot',{
      "email":email
    }).then(Response=>{
       if(Response.status === 200){
         alert("An email has been sent to reset your password")
       }
    })

  }
    return(
        <div className="forgot">
           <div className="forgot-tab">
              <div className="row">
                <h3>Email address</h3>
              </div>
              <div className="row">
                 <input 
                  className="input input-forgot"
                  placeholder="Please enter your Email address"
                  onChange={handleChange}
                 />
              </div>
              <div className="row">
                <button className="button" type="submit" onClick={handleSubmit}>
                  SUBMIT
                </button>

              </div>
           </div>
        </div>
    )
}

export default withRouter(ForgotAp)